.allocation-form {

    .modal-dialog {
        // margin: auto auto !important;
    }

    .upload-type-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .select,
        .alert {
            max-width: 15rem;
            width: 100%;
        }
    }

    .file-upload-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex-direction: column;

        .upload-area {
            width: 400px;
        }

        .input {
            display: none;
        }

        .file-upload {
            height: 20rem;
            width: 20rem;
            border: 1.5px dashed black;
            box-shadow: $box-shadow;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background: $lightgray;
            text-align: center;
            ;

            &-content {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;

                i {
                    font-size: 40px;
                    margin-bottom: 1rem;
                }
            }

            &-template {
                max-width: 20rem;
                text-align: center;
                display: flex;
                flex-direction: column;
            }
        }

    }

    .schedule-container {
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;


        .time-label {
            width: 5rem;
            // font-size: 1rem;
        }

        .schedule-label {
            width: 7rem;
            display: flex;
            justify-content: center;
            // font-weight: 500;
            // font-size: 1rem;
        }

        .varies-label {
            width: 2rem;
            display: flex;
            justify-content: center;
            // font-weight: 500;
            // font-size: 1rem;
        }

        .varies-check {
            width: 4rem;
            justify-content: center;
        }

        label {
            font-size: .8rem !important;
        }

        .time-picker-container {
            width: 7rem;
        }
    }

    .error-text {
        color: red;
    }

    .alert {
        padding: 5px 10px !important;
        margin-bottom: 0;
        margin-top: 2px;

        p {
            // color: black;
            font-size: 1rem;
            font-weight: 400;
        }

    }

    .note {
        color: #ff000099;
        font-size: 1rem !important;
    }



    .input-person {

        &:focus-within {

            //hide valude when focus input
            .css-1uccc91-singleValue {
                display: none !important;
            }
        }
    }

    .required {
        color: $red;
        padding-left: 2px;

        &-flag-text {
            color: $red;
            text-align: center;
        }
    }

    .flags-container {
        justify-content: space-between !important;
        display: flex !important;

        font-size: .8rem !important;

        .col {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 8rem;
        }

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    .one-flag.col {
        // max-width: 8rem;
    }

    .view-text {
        font-size: 1.15rem;
    }



}


.allocation-model-new .modal-dialog {
    max-width: 1350px !important;

    .modal-header {
        padding: .5rem 2rem !important;
        border-bottom: none;
    }

    .modal-body {
        padding: 1rem 3rem !important;
    }

    .modal-content {}
}

.allocation-type-select {
    padding: 0;
    display: block;
    padding-left: 5px;
    height: calc(1.5em + .75rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.allocation-upload {
    text-align: center;

    & .row {
        justify-content: center;
    }
}