.hidden {
    display: none !important;
}

// .container-main {
//     margin: 0 auto;
//     padding: 0 1rem !important;
//     width: 98vw;
//     // padding: 0 1rem;
// }

.scroll-container {
    overflow-y: auto;
    // max-height: 90%;
    flex-grow: 1;
}

.allocations-none {
    text-align: center;
}

// .input {
//     padding: 2px 8px;
//     width: 100%;
//     height: 2.5rem;
//     background-color: hsl(0, 0%, 100%);
//     border-color: hsl(0, 0%, 80%);
//     border-radius: 4px;
//     border-style: solid;
//     border-width: 1px;
//     cursor: default;

//     &:focus {
//         outline: none !important;
//         border: 2px solid #2684ff !important;
//     }
// }

// .panel {
//     background-color: white;
//     width: 100%;
//     height: 100%;
//     max-height: $maxheight;
//     display: flex;
//     min-height: $maxheight;
//     // background-color: $lightblue;
//     box-shadow: 0 2px 6px rgba(100, 100, 100, 0.3);
//     padding: 0 !important;
//     overflow-y: auto;
// }

//overide bootstrap primary button
// .btn {
//     &-primary {
//         background-image: linear-gradient($green, $lightgreen) !important;
//         border-color: transparent !important;
//         font-weight: 500 !important;
//         text-decoration: none !important;

//         &:focus {
//             background-color: $green !important;
//             box-shadow: 0 0 0.2rem $lightgreen !important;
//         }
//     }

//     &-link:hover {
//         color: #0056b3 !important;
//     }
// }

input[type='checkbox'] {
    height: 1.25rem;
    width: 1.25rem;
}

.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

// STOPGAP SOLUTIONS TO HELP THIS GET DONE, THIS IS NOT GOOD CODE
// WS
h6 .fa-dot {
    display: none !important;
}

.container-checkbox input:checked ~ .checkmark {
    background-color: #07393c !important;
}

.container-checkbox input:checked {
    background: #07393c;
}

.sidebar .nav.many .nav-item .nav-link {
    font-size: 0.85rem;
}

.people-search .css-1fhf3k1-control {
    background: #ffffff !important;
    opacity: 0.5;
}

.css-1pahdxg-control {
    border-color: #07393c !important;
    box-shadow: 0 0 2px #07393c !important;
    border-radius: 0.6rem !important;
}

.css-4ljt47-MenuList div:hover {
    background: #07393c !important;
    color: #fff;
}

.css-1okebmr-indicatorSeparator {
    display: none;
}

.atlas-theme .sidebar {
    overflow-y: auto !important;
}

.search-list {
    .form-control {
        background-color: #e4eff0 !important;
    }
}

.skeleton {
    background-color: #808080b3 !important;
}

.allocation-toggle {
    .form-check {
        display: flex !important;
        align-items: center;

        b,
        strong {
            font-weight: unset !important;
        }

        label {
            margin-left: 0.5rem;
        }
    }

    label {
        margin-bottom: 0 !important;
    }

    .people-search {
        width: unset !important;
        min-width: 25rem;
    }
}

.alert-warning {
    color: #856404 !important;
    font-weight: 500;
}

.drawer .nav-link {
    color: #07393c !important;
}
.drawer .nav-item.active,
.drawer .nav-link.active {
    color: #fff !important;
    background: #07393c !important;
}

.loading-container {
    z-index: 50 !important;
}
.drawer .card.mt-3 {
    z-index: 1;
}

#root {
    overflow-y: hidden;
    height: 100vh;
}
