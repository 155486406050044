.vcs {

    &-radio-container {
        display: flex;
        align-items: center;
        font-size: 1rem;
        flex-direction: column;
        align-items: flex-start;

        & label {
            margin-bottom: 0;
            margin-right: .5rem;
        }

        & input {
            margin-right: 2rem;
            width: 1.5rem;
            height: 1rem;
        }

        &>* {
            margin-bottom: .5rem;
        }
    }

    &-file-upload-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex-direction: column;

        label {
            width: 100%;
        }

        .input {
            display: none;
        }

        .file-upload {
            height: 10rem;
            width: 100%;
            border: 1.5px dashed black;
            box-shadow: $box-shadow;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background: $lightgray;
            text-align: center;

            &-content {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;

                i {
                    font-size: 40px;
                    margin-bottom: 1rem;
                }
            }

            &-template {
                max-width: 20rem;
                text-align: center;
                display: flex;
                flex-direction: column;
            }
        }
    }
}

.photo-tooltip {
    & .tooltip-inner {
        width: 30rem !important;
        max-width: none !important;
        text-align: start;
    }

    max-width: none !important;
    width: 30rem !important;
}