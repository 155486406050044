// body {
//     background-color: $dark-green !important;
//     margin: 0;
//     padding: 0;
// }


//global app container
@media (min-width: 2200px) {

    .container-main {
        max-width: 2200px;
    }
}

.container-main {
    width: 100%;
}

.profile-hover {
    z-index: 22 !important
}

.dim {
    opacity: 0.5;
}