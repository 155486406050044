.action-icons {

    &-list {
        &-item {
            padding: .5rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            i {
                margin-right: 10px
            }

            &:hover {
                background-color: $lightgray;
            }

        }
    }
}