// $red: #8d3433;
$red: #E3124B;
// $red:#ff000094;
$green: #07393c;
$lightgreen: #bdd8;
$lightblue: #CFDCE3;
$darkblue: #d0dde4;
$lightgray: #F1F1F1;
$bordercolor: #BCBCBC;
$box-shadow: 0 2px 6px rgba(100, 100, 100, 0.3);
$maxheight: 75vh;
$input-height: 2.5rem;
$input-border: 1px solid #767676;
$input-border-radius: 6px;
$input-background: #f1f3f6;
$input-transition: border-color .15s ease-in-out,
box-shadow .15s ease-in-out;

//ActionButton Colors
$error: #8d3433;
$warning: #F1C134;
$success: #a1cae0;

%focus-class {
    color: #495057;
    background-color: #fff;
    // border-color: #f580a0 !important;
    // border-color: #2684ff !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px #2684ff !important;
    // box-shadow: 0 0 0 2px #2684ff !important;
    border-color: #07393c !important;
    box-shadow: 0 0 2px #07393c !important;
    border-radius: 0.6rem !important;
}

%hover-class {
    box-shadow: 0 0 0 0.2rem rgba(32, 68, 125, 0.15);
}

// * {
//     --sapList_HeaderBackground: transparent !important;
//     --sapList_Background: transparent !important;
//     --sapContent_LabelColor: grey !important;
// }