.required-validation {
    color: #fff;
    font-weight: 300;
    background: #E3124B;
    display: inline-block;
    height: auto;
    margin-top: .4rem;
    font-size: .7875rem;
    padding: .1875rem 0.5rem;
    right: 4.5rem;
    position: absolute;
    border-radius: .3rem;
}

.shake-horizontal {
    -webkit-animation: shake-horizontal 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    animation: shake-horizontal 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

// input {
//     min-height: calc(1.5em + .75rem + 2px);
//     width: 100%;
//     // border: none;
//     border-radius: .6rem;
//     // background: #f1f3f6;

// }




.form-row-component-container {
    display: flex;
    flex-grow: 1;

    input {
        width: 100%;
    }
}

.text-required-dot-1 {
    background: #E3124B;
    display: inline-block;
    height: 10px;
    width: 10px;
    margin-top: .75rem;
    right: 3rem;
    position: absolute;
    border-radius: .9rem;
}

.input {
    padding: 2px 8px;
    width: 100%;
    height: 2.5rem;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: .65rem;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    color: black !important;
    font-weight: 400 !important;

    font-weight: 300;
    color: #000;
    border-radius: .6rem;
    border-width: 0rem;
    background: $input-background;

    &:focus {
        @extend %hover-class
    }
}

.form-control {
    &:focus {
        @extend %hover-class
    }
}


.css-1uccc91-singleValue {
    color: black !important;
    font-weight: 400 !important;
}

.css-107lb6w-singleValue {
    color: black !important;
    font-weight: 400 !important;
}

.css-yk16xz-control {
    font-weight: 300;
    color: #000;
    border-radius: .6rem;
    border-width: 0rem;
    background: $input-background;
}

// .css-yk16xz-control:focus,
// .css-yk16xz-control:focus,
// .css-1pahdxg-control:focus {
//     border-color: red !important;
// }

.sub-label {
    margin: 0 0;
    color: $red;
    font-weight: 100;
    margin-top: -.7rem;
}

.check-box {
    display: block;
    width: 1.5rem !important;
    height: 1.2rem;
    background: $input-background;
    border-color: hsl(0, 0%, 80%);
    border-radius: .65rem;
    border-style: solid;
    border-width: 1px;
}

.upload {


    &-radio-container {
        display: flex;
        justify-content: center;
        align-items: center;

        input[type=radio] {
            height: 1.1rem;
            width: 2rem;
        }

        label {
            margin-bottom: 0;
        }
    }

    &-button-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-note-text {
        text-align: center;
        color: $red;

        span {
            // color: ;
        }
    }
}