.table-generic {
    table-layout: fixed;
    // display: block;

    tr:hover {
        .action-button {
            visibility: initial !important;
        }
    }

    th {
        border-top: none !important;
    }

    tbody {
        tr {
            &:hover {
                background: $lightgray;
            }
        }
    }

    .action-buttons-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 4rem;

        .action-button {
            visibility: hidden;

            &-0:hover {
                color: $success;
            }

            &-1:hover {
                color: $warning;
            }

            &-2:hover {
                color: $error;
            }
        }
    }


}



.advanced-table {

    &-scroll-container {
        height: calc(100vh - 15rem);
        overflow-y: auto;

        & .btn:hover {
            color: $green !important;

        }
    }

    .action-column {
        width: 7rem;
    }

    .ui5-table-row-popin-title {
        font-weight: 800 !important;

    }




    --sapList_HeaderBackground: transparent !important;
    --sapList_Background: transparent !important;
    --sapContent_LabelColor: black;
    // --sapList_BorderColor:#bdd8da;
}

//get table to scroll properly
.detail .detail-contents {
    overflow-y: hidden !important;
}

.search-container {
    .advanced-table {

        &-scroll-container {
            height: calc(100vh - 18rem) !important;
        }
    }
}

.icon-cell {
    display: flex;
    justify-content: center;
    // width: 3rem;

}