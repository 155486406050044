.people-search {
    width: 100%;
    text-align: left;

    & .css-yk16xz-control {
        // height: 3rem;
        width: 100%;
        // border: none;
        border-radius: .6rem;
        // background: #f1f3f6;
        font-weight: 300;
        color: #000;
        border-radius: .6rem;
        border-width: 0rem;
        background: $input-background;

        &:focus {
            @extend %hover-class;
        }

        &:hover {
            @extend %hover-class;
        }
    }

    & .css-yk16xz-control:focus {
        @extend %focus-class;
    }



    & .css-1fhf3k1-control {
        background: #cccdd0;
    }

}

input {
    &:hover {
        @extend %hover-class;
    }

    // &:focus {
    //     @extend %focus-class;
    // }
}

.input,
textarea {
    &:focus {
        @extend %focus-class;
    }
}