.icon {
    // visibility: hidden;
    // margin: 5px 0 0 0 !important;
    color: grey;
    cursor: pointer;

    &-clear:hover {
        color: $red !important;
    }

    &-edit:hover {
        color: #F1C134 !important;
    }

    &-info:hover {
        color: #a1cae0 !important;
    }

    &-approve:hover {
        color: $green;
    }

    &-pending {
        // visibility: visible !important;
        color: #F1C134 !important;
        // float: right;
    }

    &-focus {
        color: $green;
        visibility: initial !important;
    }

    &-completed {
        color: $green;
    }

    &-not-completed {
        color: $red;
    }
}