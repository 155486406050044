.popover-filter {

    .MuiPopover-paper {
        overflow-x: unset !important;
        overflow-y: unset !important;
    }


    &-container {
        width: 20rem;
        padding: 5px;
    }

}

.icon-actions-popover {
    .MuiPopover-paper {
        overflow-x: unset !important;
        overflow-y: unset !important;
    }

    &-container {
        width: 13rem;
        padding: 0px;
    }
}