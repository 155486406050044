.date-picker {
    width: 100%;
    border: 0px solid #ced4da !important;
    border-radius: .6rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    background: $input-background;
    height: 2.5rem;

    &:focus-within {
        outline: none !important;
        // border: 2px solid #2684ff !important;
        @extend %focus-class;
    }

    &:hover {
        @extend %hover-class;
    }

    input {
        &:hover {
            border: none !important;
            box-shadow: none !important;
        }
    }


    .MuiInputBase-input {
        padding: 5px 10px !important;
    }

    .MuiInput-root,
    .MuiIconButton-root {
        height: 2.5rem;
        padding: 0;

        &:focus {
            outline: none !important;
            // border: 2px solid #2684ff !important;
            @extend %focus-class;
        }
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
        border-bottom: none !important;
        content: '';
    }




}

// .MuiPickersBasePicker-container .MuiPickersToolbar-toolbar {
//     background-color: $green !important;
// }